@import "./_settings.scss";

html {
  background: $color--background;
  background: linear-gradient(
    to bottom,
    $color--background 0%,
    $color--background-alt 100%
  );

  &:before {
    content: "";
    z-index: -1;
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background: linear-gradient(
      to bottom,
      $color--background 0%,
      transparentize($color--background-alt, 0.3) 100%
    );
  }
}

body {
  font-family: "Helvetica Neue", Roboto, "Segoe UI", sans-serif;
  color: $color--text;
  overflow-y: scroll;
  -webkit-font-smoothing: antialiased;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 2em;

  .wrapper {
    margin: auto 0;
  }
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

a {
  color: $color--link;
  text-decoration: none;
  transition: color 0.3s;
  cursor: pointer;

  &:hover {
    color: $color--link-hover;
  }
}

a,
button {
  &.button {
    position: relative;
    display: inline-block;
    padding: 10px 16px;
    border: 0;
    border-radius: 3px;
    color: $color--background;
    background-color: $color--link;
    cursor: pointer;
    transition: background-color 0.3s;
    text-decoration: none !important;

    &:hover {
      background-color: $color--link-hover;
    }

    &.secondary {
      color: $color--link;
      background-color: transparent;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 2px solid currentColor;
        border-radius: inherit;
      }

      &:hover {
        color: $color--link-hover;
      }
    }
  }
}

img {
  max-width: 100%;
}

iframe[src*="//codepen.io/"]
{
  box-shadow: 0 1px 1px 0 transparentize(#000, 0.7);
}
