@import "../../style/_settings.scss";

.footer {
  position: relative;
  background-color: $color--light;

  &:before {
    z-index: 2;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 5px;
    background-color: $color--light;
  }

  > div {
    z-index: 1;
    position: relative;
    text-align: center;
    max-width: $max-width;
    margin: 0 auto;
    padding: 30px 0 30px;
  }

  .social_links {
    li {
      display: inline-block;
      margin: 0 14px;
    }

    a {
      display: inline-block;
      opacity: 0.6;
      transition: opacity 0.3s;

      &:hover {
        opacity: 0.9;

        svg {
          transform: scale(1.2);
        }
      }
    }

    svg {
      fill: $color--text;
      width: 30px;
      transition: transform 0.3s;
    }
  }
}
