@import "../../style/_settings.scss";

.header {
  overflow: hidden;
  background-color: $color--light;

  a {
    color: $color--text;

    &:hover {
      color: $color--text;
    }
  }

  a.current {
    border-bottom: 2px solid $color--link;
  }

  .navigation {
    max-width: $max-width-text;
    margin: 0 auto;
    padding: 0 $side-padding;

    .brand,
    nav {
      text-align: center;
    }

    .brand {
      margin: 10px 0;

      a {
        padding: 10px 5px;
        text-decoration: none;
      }
    }

    nav {
      ul {
        li {
          display: inline-block;
          margin: 0 2px;

          a {
            display: inline-block;
            text-decoration: none;

            margin: 0 6px 10px;
            padding: 5px 2px;
          }
        }
      }
    }

    @media (min-width: 440px) {
      display: flex;
      align-items: baseline;

      .brand,
      nav {
        flex-grow: 1;
      }

      .brand {
        margin-bottom: 0;
        text-align: left;
      }

      nav {
        text-align: right;

        ul {
          li {
            a {
              margin: 10px 6px;
            }
          }
        }
      }
    }

    @media (min-width: 700px) {
      font-size: $font-size--header;
    }

    @media (min-width: 1110px) {
      max-width: $max-width;
    }

    @media (min-width: 1660px) {
      display: flex;
      max-width: none;
      padding: 0;

      .brand,
      nav {
        flex-grow: 1;
        flex-basis: 1px;
        text-align: center;
      }

      .spacer {
        flex-grow: 1;
        flex-shrink: 1;
        width: $max-width;
        max-width: $max-width;
      }
    }
  }
}
