@import "../../style/_settings.scss";

.center {
  overflow: hidden;
  box-sizing: border-box;
  max-width: $max-width;
  margin: 0 auto;
  padding: 0 $side-padding;

  &:nth-child(2n) {
    background-color: $color--light;
  }

  .wrapper {
    max-width: $max-width-text;
    margin: 30px auto;

    @if ($debug) {
      background-color: transparentize(red, 0.8);
    }
  }
}
