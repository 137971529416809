@import "../../style/_settings.scss";

.editorial {
  line-height: 23px;

  code {
    font-size: em(15px, $font-size);
    font-family: Consolas, monospace, sans-serif;
    background-color: $color--code-background;
    color: $color--code;
    padding: em(4px, $font-size) em(6px, $font-size);
    white-space: pre;
    word-wrap: normal;
  }

  pre {
    font-size: $font-size;
  }

  pre code {
    display: block;
    color: $color--code;
    border-left: 8px solid $color--code;
    padding: 8px 15px;
    overflow: auto;
    line-height: 23px;
    word-wrap: normal;
    margin: 5px 0 20px;
  }

  figure {
    margin: 0 0 20px;

    img {
      display: block;
      margin: 0 auto;
    }

    pre code {
      margin: 0;
    }

    figcaption {
      font-size: 14px;
      line-height: 20px;
      margin: 7px 0 25px;
      opacity: $opacity--text-dim;
      text-align: center;
    }
  }

  ul,
  ol {
    margin: 0 0 20px 20px;

    li {
      margin: 12px 0;
      list-style: disc;
    }

    ul,
    ol {
      margin-bottom: 0;
    }
  }

  ol li {
    list-style: decimal;
  }

  h1 {
    font-size: 28px;
    line-height: 35px;
    font-weight: bold;
    margin: 0 0 20px;
  }

  h3 {
    font-size: 22px;
    line-height: 29px;
    font-weight: bold;
    margin: 0 0 15px;
  }
  h4 {
    font-size: 20px;
    line-height: 27px;
    font-weight: bold;
    margin: 0 0 15px;
  }
  h5 {
    font-size: $font-size;
    line-height: 25px;
    font-weight: bold;
    margin: 0 0 15px;
  }
  h6 {
    font-size: $font-size;
    line-height: 25px;
    font-weight: normal;
    margin: 0 0 15px;
    text-transform: uppercase;
  }

  s {
    text-decoration: line-through;
    color: #f33737;
  }

  a {
    text-decoration: underline;
    text-decoration-skip-ink: none;
    text-decoration-color: transparentize($color--link, 0.5);

    &:hover {
      text-decoration-color: transparentize($color--link-hover, 0.5);
    }
  }

  mark {
    background-color: #cdce02;
    color: $color--background;
  }

  // .float-left {
  //   float: left;
  //   margin-right: 20px;
  // }

  // .float-right {
  //   width: 50%;
  //   float: right;
  //   margin-left: 20px;
  // }

  // .float-left,
  // .float-right {
  //   width: 50%;
  //   margin-bottom: 10px;

  //   @media (min-width: 400px) {
  //     width: 40%;
  //   }

  //   @media (min-width: 700px) {
  //     width: 30%;
  //   }
  // }

  blockquote {
    display: block;
    margin: 0.5em 0 1em;
    border-left: 8px solid #c4563a;
    padding: 0.5em 1em;
    line-height: 1.556em;
    background-color: $color--light;
  }

  :global(.table-wrap) {
    overflow: auto;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
    border: 0;
    border: 1px solid transparentize(#000, 0.6);
    width: 100%;

    thead th {
      background-color: transparentize(#000, 0.4);
      border-color: $color--light-line;
      color: $color--code;
      text-align: left;
    }

    tr {
      background-color: $color--light;

      &:nth-child(even) {
        background-color: transparentize(#000, 0.9);
      }
    }

    td,
    th {
      padding: 10px;
      vertical-align: top;
    }
  }

  :global(.button-list) {
    font-size: $font-size;
    line-height: 28px;
    margin: 0 -3px 15px;

    :global(.button) {
      margin: 5px 3px;
    }
  }
}

.h2,
.editorial h2 {
  font-size: 25px;
  line-height: 33px;
  font-weight: bold;
  margin: 0 0 20px;
}

.paragraph,
.editorial p {
  font-size: $font-size;
  line-height: 28px;
  margin: 0 0 20px;

  &:global(.entry) {
    font-size: 22px;
    line-height: 29px;
    margin-bottom: 25px;
  }

  &:global(.caption) {
    font-size: 14px;
    font-style: italic;
    line-height: 20px;
    margin: 7px 0 25px;
    opacity: $opacity--text-dim;
  }
}
